/*
 * GAME BACKEND ON-CHAIN CODE:
 *
 * Code gets executed on the on-chain through GameCaster.
 *
 * Each script consists of and array of functions,
 *   1. The last function return an JSUpdate to update the game-state writing
 *   in the KEY-VALUE database or WALLET-KEY-VALUE database
 *
 *   2. The other functions can return JSFetch to fetch data from the game state.
 *
 * These functions get executed in order, and pass their results to the next function.
 * This way you can fetch data, and make decisions based on that data.
 *
 * For example you can fetch the player's current lives and check if they can start a new level
 *
 * IMPORTANT: The code cannot contain any | symbols.
 * This is a limitation when posting the code on-chain, as the | is used as delimiter symbol.
 * We are working on a fix for this.
 */
export const flipCardCode = [
    // The game input sends a value with the format "some-key#selected-card"
    // We split the value to get the KEY and SELECTED CARD
    // We request now the WALLET-KEY-VALUE database to get the value of the KEY
    function main(setup, input, _) {
        const [key] = input.value.split('#');
        return [{ key, wallet: setup.wallet }];
    },
    // In this function we check if the KEY has a value in the WALLET-KEY-VALUE database
    //
    // 1.
    // If it does has the value, this means the GAME HAS ALREADY BEEN PLAYED.
    // so we return no updates, and ignore the game input.
    // This might be a duplicated request, or some player sending arbitrary data.
    //
    // 2.
    // If the KEY does not have a value, we generate a random card, 
    // and check if the selected card matches.
    // If it does, we return a prize!
    // Finally we update the WALLET-KEY-VALUE database
    //
    function main(setup, input, context) {
        if (context.function0.length === 0) {
            const possibleCards = ['c1', 'c2', 'c3', 'c4', 'c5', 'c6', 'c7', 'c8', 'c9'];
            const s = Math.abs(setup.seed);
            const randomCard = possibleCards[s % possibleCards.length];
            const [key, card] = input.value.split('#');
            const win = card === randomCard;
            return {
                prize: win ? Math.max((s % 100) / 1000, 0.01) : 0,
                updates: [
                    {
                        key,
                        wallet: setup.wallet,
                        value: JSON.stringify({
                            win,
                            selected: card,
                            winner: randomCard,
                        }),
                    },
                ],
            };
        }
        else {
            return {
                updates: [],
            };
        }
    },
];
// This is the code for items usage.
export const useItemCode = [
    // This function stores in the WALLET-KEY-VALUE database the item used by the player.
    function main(setup, input, _) {
        return {
            updates: Array(input.amount)
                .fill(0)
                .map((_, i) => ({
                wallet: setup.wallet,
                key: 'item-' + i + '-' + input.itemName,
                value: setup.block_height.toString(),
            })),
        };
    },
];
