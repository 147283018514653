var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { LocalBackend } from './LocalBackend.js';
import { GameParams } from './GameParams.js';
import { DevOverlay } from './DevOverlay.js';
export class GameCasterBase {
    constructor(isLocalBackend) {
        this.gameParams = new GameParams(isLocalBackend);
    }
    connectEvents(blockCallback, itemCallback) {
        return __awaiter(this, void 0, void 0, function* () {
            window.addEventListener('message', event => {
                if (event &&
                    event.data &&
                    typeof event.data === 'object' &&
                    event.data.target === 'iframe-game' &&
                    event.data.action === 'event') {
                    console.log(`GAME CASTER EVENT: ${JSON.stringify(event.data)}`);
                    blockCallback(event.data.payload);
                }
                if (event &&
                    event.data &&
                    typeof event.data === 'object' &&
                    event.data.target === 'iframe-game' &&
                    event.data.action === 'game_action') {
                    console.log(`GAME CASTER GAME-EVENT: ${JSON.stringify(event.data)}`);
                    itemCallback(event.data.payload);
                }
            }, false);
        });
    }
}
export class GameCasterLocalDev extends GameCasterBase {
    constructor(backendSetup) {
        super(true);
        this.backendSetup = backendSetup;
        this.localBackend = new LocalBackend(this);
        this.devOverlay = new DevOverlay(this);
    }
    connectWallet() {
        return __awaiter(this, void 0, void 0, function* () {
            window.parent.postMessage({ target: 'game-caster', action: 'connectWallet' }, '*');
        });
    }
    localBuyItem(item) {
        return __awaiter(this, void 0, void 0, function* () {
            setTimeout(() => {
                this.localBackend.buyItem(this.gameParams.wallet, item);
            }, 1000);
        });
    }
    userInteraction(state, input) {
        return __awaiter(this, void 0, void 0, function* () {
            var _a;
            const code = (_a = this.backendSetup.backendCode.find(c => c.state === state)) === null || _a === void 0 ? void 0 : _a.callback;
            if (!code) {
                console.log(`No code found for state: ${state}`);
                return;
            }
            const result = yield this.localBackend.runProgram({
                wallet: this.gameParams.wallet || '0x0',
                block_height: LocalBackend.block,
                seed: LocalBackend.seed,
                erc_index: parseInt(this.gameParams.erc20index || '0', 10),
            }, {
                input: 'input',
                erc_index: parseInt(this.gameParams.erc20index || '0', 10),
                action: state,
                value: input,
            }, { javascript: code });
            this.localBackend.addToJackpot(1000);
            if (result.prize) {
                const prize = Math.round(result.prize * this.localBackend.getJackpot());
                this.localBackend.addToJackpot(-1 * prize);
                console.log(`winner of prize: ${prize}`);
            }
        });
    }
    useItem(item, metadata) {
        return __awaiter(this, void 0, void 0, function* () {
            this.localBackend.useItem(this.gameParams.wallet, item, 1, metadata, {
                wallet: this.gameParams.wallet || '0x0',
                block_height: LocalBackend.block,
                seed: LocalBackend.seed,
                erc_index: parseInt(this.gameParams.erc20index || '0', 10),
            });
        });
    }
    getWalletKeyValue(keys) {
        return __awaiter(this, void 0, void 0, function* () {
            return Promise.all(keys
                .map((k) => __awaiter(this, void 0, void 0, function* () {
                const value = yield this.localBackend.getWalletKeyValue(this.gameParams.wallet || '0x0', k);
                if (value == null)
                    return null;
                return {
                    erc20_index: parseInt(this.gameParams.erc20index || '0', 10),
                    key: k,
                    value,
                };
            }))
                .filter(v => !!v));
        });
    }
    getKeyValue(keys) {
        return __awaiter(this, void 0, void 0, function* () {
            return Promise.all(keys
                .map((k) => __awaiter(this, void 0, void 0, function* () {
                const value = yield this.localBackend.getKeyValue(k);
                if (value == null)
                    return null;
                return {
                    erc20_index: parseInt(this.gameParams.erc20index || '0', 10),
                    key: k,
                    value,
                };
            }))
                .filter(v => !!v));
        });
    }
    getCurrentJackpot() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.localBackend.getJackpot();
        });
    }
    getItems() {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                items: this.backendSetup.items.map(i => ({
                    created_block: 0, // Assuming this is not relevant for local items
                    description: i.description,
                    erc20_index: parseInt(this.gameParams.erc20index || '0', 10),
                    item: i.name,
                    price: i.price,
                    type: 'image/main',
                })),
                images: this.backendSetup.items.map((i, index) => ({
                    erc20_index: parseInt(this.gameParams.erc20index || '0', 10),
                    id: index,
                    object_id: i.name,
                    object_type: 'item',
                    type: 'image',
                    uri: i.devImage,
                    weight: 1,
                })),
            };
        });
    }
    getInventory() {
        return __awaiter(this, void 0, void 0, function* () {
            const items = yield this.getItems();
            const inventory = items.items.map(item => ({
                created_block: LocalBackend.block,
                erc20_index: parseInt(this.gameParams.erc20index || '0', 10),
                item: item.item,
                modified_block: LocalBackend.block,
                quantity: this.localBackend.getInventoryItemCount(this.gameParams.wallet || '0x0', item.item),
                wallet: this.gameParams.wallet || '0x0',
            }));
            return { inventory };
        });
    }
}
export class GameCasterRemote extends GameCasterBase {
    constructor() {
        super(false);
        this.itemCache = null;
    }
    connectWallet() {
        return __awaiter(this, void 0, void 0, function* () {
            window.parent.postMessage({ target: 'game-caster', action: 'connectWallet' }, '*');
        });
    }
    localBuyItem(_) {
        return __awaiter(this, void 0, void 0, function* () {
            // Not implemented for remote version
            throw new Error('Not for remote version');
        });
    }
    userInteraction(state, input) {
        return __awaiter(this, void 0, void 0, function* () {
            var _a;
            const payload = {
                action: state,
                input,
                subtype: 'input',
                erc20_index: parseInt((_a = this.gameParams.erc20index) !== null && _a !== void 0 ? _a : '0'),
            };
            window.parent.postMessage({
                target: 'game-caster',
                action: 'sendTX',
                payload,
            }, '*');
        });
    }
    useItem(item, metadata) {
        return __awaiter(this, void 0, void 0, function* () {
            var _a;
            const payload = {
                input: metadata,
                item: item,
                subtype: 'use-item',
                amount: 1,
                erc20_index: parseInt((_a = this.gameParams.erc20index) !== null && _a !== void 0 ? _a : '0'),
            };
            window.parent.postMessage({
                target: 'game-caster',
                action: 'sendTX',
                payload,
            }, '*');
        });
    }
    getWalletKeyValue(keys) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield fetch(`${process.env.BACKEND_URI}/game/${this.gameParams.erc20index}/keys-wallet/${this.gameParams.wallet}?${keys.map(k => `keys=${k}`).join('&')}`);
            if (!result.ok)
                throw new Error('Cannot fetch');
            const data = yield result.json();
            return data.values;
        });
    }
    getKeyValue(keys) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield fetch(`${process.env.BACKEND_URI}/game/${this.gameParams.erc20index}/keys/${this.gameParams.wallet}?${keys.map(k => `keys=${k}`).join('&')}`);
            if (!result.ok)
                throw new Error('Cannot fetch');
            const data = yield result.json();
            return data.values;
        });
    }
    getCurrentJackpot() {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield fetch(`${process.env.BACKEND_URI}/game/${this.gameParams.erc20index}/tokens`);
            if (!result.ok)
                throw new Error('Cannot fetch');
            const data = yield result.json();
            return data.token.available;
        });
    }
    getItems() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.itemCache)
                return this.itemCache;
            const result = yield yield fetch(`/game/${this.gameParams.erc20index || '0'}/items`);
            if (!result.ok)
                throw new Error('Cannot fetch');
            const data = yield result.json();
            this.itemCache = data;
            return this.itemCache;
        });
    }
    getInventory() {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield yield fetch(`/game/${this.gameParams.erc20index || '0'}/inventory/${this.gameParams.wallet || '0x0'}`);
            if (!result.ok)
                throw new Error('Cannot fetch');
            const data = yield result.json();
            return data;
        });
    }
}
