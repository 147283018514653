"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * This class implements a simple HTML overlay for the items in
 * the player's inventory, and allows the player to use items.
 */
class ItemsOverlay {
    constructor(api) {
        this.api = api;
        this.itemElements = new Map();
        this.container = document.createElement('div');
        this.container.id = 'item-overlay';
        this.container.style.position = 'fixed';
        this.container.style.left = '10px';
        this.container.style.top = '50%';
        this.container.style.transform = 'translateY(-50%)';
        this.container.style.display = 'flex';
        this.container.style.flexDirection = 'column';
        this.container.style.gap = '10px';
        this.container.style.zIndex = '1000';
        document.body.appendChild(this.container);
        this.renderItems();
    }
    renderItems() {
        return __awaiter(this, void 0, void 0, function* () {
            const itemsData = yield this.api.getItems();
            for (const item of itemsData.items) {
                const itemElement = document.createElement('div');
                itemElement.style.display = 'none'; // Initially hide all items
                itemElement.style.flexDirection = 'column';
                itemElement.style.alignItems = 'center';
                itemElement.style.padding = '10px';
                itemElement.style.borderRadius = '5px';
                itemElement.style.cursor = 'pointer';
                itemElement.style.backgroundColor = 'rgba(0, 0, 0, 0.6)';
                const img = document.createElement('img');
                const itemImage = itemsData.images.find(image => image.object_id === item.item);
                img.src = itemImage ? itemImage.uri : 'default-image-path.png';
                img.alt = item.item;
                img.style.width = '50px';
                img.style.height = '50px';
                img.style.borderRadius = '4px'; // Add rounded edges to the image
                const name = document.createElement('div');
                name.textContent = item.item;
                name.style.color = 'white';
                name.style.fontSize = '14px';
                name.style.fontWeight = 'bold';
                name.style.marginTop = '5px';
                name.style.marginBottom = '3px';
                const amount = document.createElement('div');
                amount.textContent = 'Loading...';
                amount.style.fontSize = '12px';
                amount.style.color = 'white';
                itemElement.appendChild(img);
                itemElement.appendChild(name);
                itemElement.appendChild(amount);
                itemElement.addEventListener('click', () => this.useItem(item.item));
                this.container.appendChild(itemElement);
                this.itemElements.set(item.item, itemElement);
                // Fetch and update the item amount
                this.updateItemAmount(item.item);
            }
        });
    }
    updateItemList() {
        return __awaiter(this, void 0, void 0, function* () {
            const itemsData = yield this.api.getItems();
            for (const item of itemsData.items) {
                yield this.updateItemAmount(item.item);
            }
        });
    }
    updateItemAmount(itemName) {
        return __awaiter(this, void 0, void 0, function* () {
            const itemElement = this.itemElements.get(itemName);
            if (!itemElement)
                return;
            try {
                const inventoryData = yield this.api.getInventory();
                const item = inventoryData.inventory.find(i => i.item === itemName);
                const amount = item ? item.quantity : 0;
                const amountElement = itemElement.querySelector('div:last-child');
                if (amountElement) {
                    amountElement.textContent = `Amount: ${amount}`;
                }
                // Show the item only if the amount is greater than zero
                itemElement.style.display = amount > 0 ? 'flex' : 'none';
            }
            catch (error) {
                console.error(`Error fetching amount for ${itemName}:`, error);
                const amountElement = itemElement.querySelector('div:last-child');
                if (amountElement) {
                    amountElement.textContent = 'Error';
                }
                itemElement.style.display = 'none';
            }
        });
    }
    useItem(itemName) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                yield this.api.useItem(itemName, 'todo-get-game-id');
                console.log(`Used item: ${itemName}`);
                // Update the item amount after using
                yield this.updateItemAmount(itemName);
            }
            catch (error) {
                console.error(`Error using item ${itemName}:`, error);
            }
        });
    }
}
exports.default = ItemsOverlay;
