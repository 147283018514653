import { flipCardCode, useItemCode } from './functions.js';
export const backendSetup = {
    // Register Backend `callbacks` that get executed
    // when calling "api.userInteraction(state, input)"
    backendCode: [
        {
            state: 'flip',
            callback: JSON.stringify(flipCardCode.map(c => c.toString())),
        },
    ],
    // This creates Game Items that can be bought in the shop
    // When used they trigger the `callback` function
    items: [
        {
            name: 'sword',
            description: 'a sword to fight with',
            price: 1200,
            callback: JSON.stringify(useItemCode.map(c => c.toString())),
            devImage: 'https://placehold.co/200x200/d35400/white?text=Sword',
        },
        {
            name: 'shield',
            description: 'defend yourself',
            price: 1500,
            callback: JSON.stringify(useItemCode.map(c => c.toString())),
            devImage: 'https://placehold.co/200x200/1abc9c/white?text=Shield',
        },
    ],
};
